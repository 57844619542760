var prodcat = prodcat || {};

(function ($, generic) {
  Drupal.behaviors.productWaitlistV1 = {
    attach: function (context, settings) {
      var that = this;

      $(document).on('product:waitlist:init', function (event, data) {
        that.init(data);
      });

      $(document).on('product:waitlist:reset', function (event, data) {
        that.reset(data);
      });
    },

    init: function (data) {
      // Only show overlay for sold out products when LIFE_OF_PRODUCT is basic reorder.
      if (
        data.INVENTORY_STATUS === this.statusIds.inventory.soldOut &&
        data.LIFE_OF_PRODUCT !== this.statusIds.lifeOfProduct.basicReorder
      ) {
        return null;
      }
      var that = this;
      var $container = $(this.classes.container).filter('[data-product-id=' + data.PRODUCT_ID + ']');
      var $waitlistBtns = $(this.classes.waitlistBtn, $container);
      var enableAddtocartNotifyme = Drupal.settings.common && Drupal.settings.common.enable_addtocart_notifyme_tos;

      $waitlistBtns.each(function (index, btn) {
        if ($(btn).hasClass(that.classes.enabled) && !enableAddtocartNotifyme) {
          return null;
        }

        that.show($container, btn, data);

        $(btn).attr('data-sku-base-id', data.SKU_BASE_ID);

        $(btn).on('click', function (e) {
          e.preventDefault();
          $(document).trigger('product:waitlist:overlay', data);
        });
      });
    },

    reset: function (data) {
      if (!data) {
        return null;
      }
      var $container = $(this.classes.container).filter('[data-product-id=' + data.PRODUCT_ID + ']');
      var $waitlistBtnContainer = $(this.classes.waitlistBtn, $container);
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $('.product-quickshop__product .product-quickshop__footer').removeClass('product-tos');
      $cartBtnContainer.html(site.translations.product.product_cta_add_to_bag);
      if (!$waitlistBtnContainer.hasClass(this.classes.enabled)) {
        return null;
      }
      this.hide($container, $waitlistBtnContainer);
    },

    show: function ($container, btn, data) {
      var enableAddtocartNotifyme = Drupal.settings.common && Drupal.settings.common.enable_addtocart_notifyme_tos;
      var $waitlistBtnContainer = $(this.classes.waitlistBtn, $container);
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      if (data.INVENTORY_STATUS !== 2 || !enableAddtocartNotifyme) {
        $cartBtnContainer.addClass(this.classes.hidden);
        $cartBtnContainer.html(site.translations.product.product_cta_add_to_bag);
      } else {
        $cartBtnContainer.removeClass(this.classes.hidden);
        $('.product-quickshop__product .product-quickshop__footer').addClass('product-tos');
        $cartBtnContainer.html(site.translations.product.product_cta_tos_add_to_bag);
      }
      if (data.INVENTORY_STATUS === 7 && data.LIFE_OF_PRODUCT === 2 && $(btn).siblings('.js-wait-list').length > 0) {
        $cartBtnContainer.addClass(this.classes.hidden);
      }
      $(btn).removeClass(this.classes.hidden);
      $(btn).addClass(this.classes.active);
      $(btn).addClass(this.classes.enabled);
    },

    hide: function ($container, $waitlistBtnContainer) {
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $cartBtnContainer.removeClass(this.classes.hidden);
      $waitlistBtnContainer.addClass(this.classes.hidden);
      $waitlistBtnContainer.removeClass(this.classes.active);
      $waitlistBtnContainer.removeClass(this.classes.enabled);
    },

    classes: {
      waitlistBtn: '.js-add-to-waitlist',
      cartBtn: '.js-add-to-cart',
      invStatus: '.js-inv-status-list',
      container: '.js-product',
      enabled: 'js-waitlist-enabled',
      hidden: 'hidden',
      active: 'active'
    },

    statusIds: {
      inventory: {
        comingSoon: 3,
        soldOut: 7
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    }
  };

  // hide inventory list/shop button according to product inventory status - fix
  /* eslint complexity: [0, 0]*/
  $(document).on('product.updateInvStatus', '.js-product', function () {
    var $addBtn = $('.product-brief__cta--shop-now', this);
    var productId = $addBtn.data('product-id') || $(this).data('product-id');

    if (productId && !productId.length) {
      return;
    }
    var productData = prodcat.data.getProduct(productId);

    if (productData) {
      if (productData.isShoppable === 1) {
        var $invStatusList = $('.product-brief__footer .product__inventory-status.js-inv-status-list', this);
        var $jsWaitListBtn = $('.product-brief__footer .js-add-to-waitlist.js-product-cta', this);

        $invStatusList.remove();
        $jsWaitListBtn.remove();
      } else {
        var $jsProductCont = $(this).parents('.product-brief__footer');

        // hide shop now CTA for products that are not shoppable
        $addBtn.addClass('hidden');
        // display notify CTA if OOS or CS
        if (
          $jsProductCont.find('.js-inv-status-list .js-inv-status-2').is(':visible') ||
          $jsProductCont.find('.js-inv-status-list .js-inv-status-3').is(':visible')
        ) {
          $jsProductCont.find('.js-add-to-waitlist').removeClass('hidden');
        }
      }
    }
  });
})(jQuery);
